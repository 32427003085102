import React from "react";

const Quote = (props) => (
  <blockquote
    className="relative p-4 pl-8 pt-5 text-xl font-serif italic border-l-4 bg-neutral-100 text-gray-600 border-gray-300 z-10
  before:absolute before:top-0 before:left-0 before:text-8xl before:content-['“'] before:text-gray-300 before:z-0 "
  >
    {/* <div className="stylistic-quote-mark" aria-hidden="true">
      &ldquo;
    </div> */}

    {/* <div
      className="absolute top-0 block mr-2 leading-none text-gray-500 text-8xl right-full"
      aria-hidden="true"
    >
      &ldquo;
    </div> */}
    {/* <p className="mb-4">"Življenje samo je najčudovitejša pravljica."</p> */}

    <p className="relative z-10">{props.quote}</p>

    {props.author && (
      <cite className="flex items-center justify-end mt-4">
        <div className="flex flex-col items-end">
          <span className="text-sm not-italic ">{props.author}</span>
        </div>
      </cite>
    )}
  </blockquote>
);

export default Quote;
