import * as React from "react";
import { Helmet } from "react-helmet";
import Quote from "../components/quotes";
import { graphql, withPrefix } from "gatsby";

import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
// import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
// https://github.com/wojtekmaj/react-pdf/issues/321
// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface IPonudbaProps {
  data: any;
}

export function Ponudba(props: IPonudbaProps) {
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const documentWrapperRef = React.useRef();

  return (
    <React.Fragment>
      <Helmet title="Ponudba | Gostilna Spirić" defer={false} />
      <article className="page">
        <section className="container flex flex-col items-center gap-5 content-card">
          <h1 className="content-title">Prelistajte našo ponudbo</h1>
          {/* <embed src="MENU.pdf" width="800px" height="2100px" /> */}
          {/* <div ref={documentWrapperRef} className="flex flex-col items-center"> */}
          <Quote
            quote="Ni ljubezni, ki bi bila iskrena kot ljubezen do hrane."
            author="G. B. Shaw"
          />

          {/* <iframe
            src="/SPIRIC_MENU.pdf#toolbar=0"
            width="100%"
            height="500px"
          ></iframe> */}

          <Document
            file={withPrefix(props.data.file.publicURL)}
            // file={withPrefix("./meni/SPIRIC_MENI_BREZ_CEN.pdf")}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={console.error}
            className="flex flex-col items-center bg-white shadow "
          >
            <Page
              pageNumber={pageNumber}
              className="h-auto overflow-hidden bg-white rounded shadow group"
              // width={300}
              // width={
              //   documentWrapperRef.current?.getBoundingClientRect().width ||
              //   undefined
              // }
            >
              <div className="absolute hidden transition duration-500 ease-in-out transform scale-90 -translate-x-1/2 bg-white shadow-2xl md:scale-110 border-1 bottom-10 left-1/2 group-hover:block">
                <button
                  className="bg-white w-11 h-11 disabled:text-gray-300"
                  onClick={() => setPageNumber(pageNumber - 1)}
                  disabled={pageNumber <= 1}
                >
                  ‹
                </button>
                <span className="px-2 font-raleway">
                  {pageNumber} od {numPages}
                </span>
                <button
                  className="bg-white w-11 h-11 disabled:text-gray-300"
                  disabled={pageNumber >= numPages}
                  onClick={() => setPageNumber(pageNumber + 1)}
                >
                  ›
                </button>
              </div>
            </Page>
          </Document>
          {/* </div> */}
          {/* <p>
              Page {pageNumber} of {numPages}
            </p> */}
        </section>
      </article>
    </React.Fragment>
  );
}

export default Ponudba;

export const query = graphql`
  query MyQuery {
    file(relativePath: { eq: "SPIRIC_MENI_BREZ_CEN.pdf" }) {
      id
      publicURL
      name
    }
  }
`;
